<template>
  <trac-loading class="pt-64" v-if="loading" />
  <main v-else class="">
    <div class="flex-col mt-6 mb-8">
      <h2 class="text-xs">Sales</h2>
      <h1 class="font-medium mt-2">Orders</h1>
    </div>
    <div class="bg-white rounded-lg overflow-hidden big-shadow">
      <div class="relative max-w-sm py-5 rounded-md w-full flex items-center">
        <input
          type="text"
          placeholder="Search by customer,phone, email"
          class="input-shadow py-2 pl-5 w-full ml-10 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
          v-model="search"
        />
        <svg
          class="w-4 h-4 absolute right-0 mr-6"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="6.56705"
            cy="6.61686"
            r="5.39909"
            stroke="#253B95"
            stroke-width="1.06786"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.3156 13.3659L10.3799 10.4302"
            stroke="#253B95"
            stroke-width="1.06786"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        :class="{ 'min-heights': getAllOrders }"
        class="mx-8 mb-8 border rounded-lg overflow-auto"
      >
        <table class="w-full table-auto overflow-auto">
          <thead class="bg-blue-100">
            <tr class="h-12">
              <th class="text-left pl-8 text-xs font-semibold py-2 uppercase">
                Date
              </th>
              <th class="text-left px-5 text-xs font-semibold py-2 uppercase">
                Customer
              </th>
              <th class="text-left text-xs font-semibold py-2 uppercase">
                Phone
              </th>
              <th class="text-left text-xs font-semibold py-2 uppercase">
                Email
              </th>
              <th class="text-left text-xs font-semibold py-2 uppercase">
                Order Amount
              </th>
              <th class="text-left text-xs font-semibold py-2 uppercase">
                State
              </th>
            </tr>
          </thead>
          <tbody v-if="getAllOrders">
            <tr
              class="cursor-pointer"
              :class="index % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(order, index) in filteredBy"
              :key="index"
              @click="
                $router.push({
                  name: 'OrderDetails',
                  params: {
                    id: order.id,
                  },
                })
              "
            >
              <td class="text-xs py-5 text-accentDark font-medium pl-8">
                {{ moment(order.date_created).format("Do-MMM-YYYY") }}
              </td>
              <td class="text-xs py-5 px-5 text-accentDark font-bold">
                {{ order.shipping.first_name }} {{ order.shipping.last_name }}
              </td>
              <td class="text-xs py-5 text-accentDark font-medium">
                {{ order.phone }}
              </td>
              <td class="text-xs py-5 text-accentDark">
                <div class="flex flex-row">
                  {{ order.email }}
                </div>
              </td>

              <td class="text-xs py-5 text-accentDark font-medium capitalize">
                {{ order.total | formatPrice }}
              </td>

              <td class="text-xs py-5 text-accentDark font-medium">
                <div class="flex flex-row capitalize">
                  {{ order.status }}
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-xs font-medium p-4" colspan="6">
                <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      There is no data available.
                    </div>
                  </trac-center-data>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import Fuse from "fuse.js";
import moment from 'moment'
export default {
  data() {
    return {
      orders: null,
      loading: false,
      moment,
      search: ''
    }
  },
  computed: {
    getAllOrders() {
      if (!!this.orders) {
        return this.orders;
      }
    },
    filteredBy() {
      if (!!this.orders) {
        let k = this.orders;
        const options = {
          keys: ["shipping.first_name", "shipping.last_name", "phone", "email"],
        };
        const fuse = new Fuse(k, options);
        let data = []
        let u = this.search == '' ? data = k : fuse.search(this.search).map((x) => data.push(x.item));
        return data
      }
    }
  },
  methods: {
    async fetchAllOrders() {
      this.loading = true
      let res = await this.$store.dispatch('FETCH_ALL_ORDERS')
      if (res.status) {
        this.orders = res.data.orderData
        this.loading = false
      }
    }
  },
  async created() {
    await this.fetchAllOrders()
  }
}
</script>

<style scoped>
.min-heights {
  height: 70vh;
}
</style>